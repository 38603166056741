import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f12d7b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex overflow-hidden shadow mb-4 border-0 card-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "d-flex justify-content-around row" }
const _hoisted_5 = { class: "col-md-6 col-12 card-title text-center" }
const _hoisted_6 = { class: "card-text mt-3 px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MemberPositionTag = _resolveComponent("MemberPositionTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "card-img-top col-4",
      src: _ctx.imgUrl
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.name), 1),
        _createVNode(_component_MemberPositionTag, { position: _ctx.position }, null, 8, ["position"])
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.content), 1)
    ])
  ]))
}