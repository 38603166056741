import { createI18n } from 'vue-i18n'
import tw from "./tw.json";
import en from "./en.json";
import jp from "./jp.json";

let locale = "tw";
if (localStorage.getItem("currentLang")) {
    if (localStorage.getItem("currentLang") != null) {
        locale = localStorage.getItem("currentLang")!;
    } 
}
type MessageSchema = typeof tw

const messages = {
    tw,
    en,
    jp,
  };

const i18n = createI18n<[MessageSchema], 'en' | 'tw' | 'jp'>({
  locale: locale,
  messages: messages
})

export default i18n;
