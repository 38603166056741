
import { defineComponent } from 'vue';
import TimelineCard from './TimelineCard.vue';

export default defineComponent({
  name: 'Timeline',
  components:{
    TimelineCard
  },
  props: {
    awards: Array
  },
});
