
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Title',
    props: {
        title: String,
        subtitle: String,
    },
});
