import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Development from '../views/Development.vue'
import Solutions from '../views/Solutions.vue'

console.log(process.env)
const isDevelopment = process.env['NODE_ENV'] == 'development'
console.log(isDevelopment)
console.log(process.env.BASE_URL)


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/solutions',
    name: 'Solutions',
    component: Solutions
  },
  {
    path: '/development',
    name: 'Development',
    component: Development
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },


  {
    path: '/:pathMatch(.*)*', 
    redirect: '/home'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
