
import { defineComponent } from "vue";
import GoogleMap from "@/components/GoogleMap.vue";
import Title from "@/components/Title.vue";



export default defineComponent({
  name: 'Contact',
  components: {
    GoogleMap,
    Title
  },
  data() {
    return {
      title: "",
      message: "",
      center: { lat: 51.093048, lng: 6.842120 },
      markers: [
        {
          position: {
            lat: 51.093048, lng: 6.842120
          },
        }
        , // Along list of clusters
      ]
    }
  },
  methods: {
    submitForm() {
      const subject = `問題反應：${this.title}`;
      const body = `您好，我想反應以下問題：${this.message}`;
      const mailToUrl = `mailto:service@heroeew.com?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      window.location.href = mailToUrl;
      this.title = '';
      this.message = '';
    },
  },

});
