import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_GMapMap = _resolveComponent("GMapMap")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_GMapMap, {
      center: { lat: 25.03597472049808, lng: 121.57494007042035 },
      zoom: 12,
      "map-type-id": "roadmap",
      style: {"width":"70vw","height":"40vh"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_GMapMarker, { position: { lat: 25.03597472049808, lng: 121.57494007042035 } })
      ]),
      _: 1
    })
  ]))
}