
import { defineComponent } from "vue";
import  MemberCard from "@/components/MemberCard.vue";

export default defineComponent({
  name: 'About',
  components: {
    MemberCard
  },
  data(){
    return {
      currentLang: localStorage.getItem("currentLang"),
      companyIntro : new Array<string>(),
      membersIntro: new Array<Record<string, string>>()
    }
  },
  methods: {
    async getCompanyIntro(){
      const url = 'companyintroduction'
      let res = await this.$api.get(url)
      var resultArray : Array<string> = []
      res.forEach(element => {
        let result = element[`content_${ this.currentLang }`]
        resultArray.push(result)
      });
      this.companyIntro = resultArray
    },
    async getMemberIntro(){
      const url = 'crew'
      let res = await this.$api.get(url)
      this.membersIntro = res
      console.log(res)
      var resultArray: Array<Record<string, string>> = []
      res.forEach(element => {
        let result: Record<string, string> = {
          id: element['id'],
          order: element['order'],
          name: element[`name_${ this.currentLang }`],
          description: element[`description_${ this.currentLang }`],
          image: element[`image_${ this.currentLang }`],
          position: element[`position_${ this.currentLang }`]
        }
        resultArray.push(result) 
      })
      this.membersIntro = resultArray
    }
  },
  created(){
    this.getCompanyIntro()
    this.getMemberIntro()
  }
});
