
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'TimelineCard',
  props: {
    id: String,
    date: String,
    title: String,
    imgUrl: String,
    content: String,
  },
});
