import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang/lang';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import axios from 'axios';
import VueAxios from 'vue-axios';
import api from '@/service/api';
// google map
import VueGoogleMaps from '@fawmi/vue-google-maps'
import '@fortawesome/fontawesome-free/css/all.css'




declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $api: api;
    }
}

const app = createApp(App).use(store).use(router).use(i18n).use(VueAxios, axios)
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDFcPvVu0_H_OI2dO4HWDZ6kOSDd1Oz8_4',
    },
}).mount('#app')

app.config.globalProperties.$api = api;
// app.mount('#app')
