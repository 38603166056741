
import { defineComponent } from "vue";
import Timeline from "../components/Timeline.vue";

export default defineComponent({
  name: 'Development',
  components: {
    Timeline
  },
  data() {
    return {
      currentLang: localStorage.getItem("currentLang"),
      awards: new Array<Record<string, string>>()
    }
  },
  methods: {
    async getAwards() {
      const url = 'award'
      let res = await this.$api.get(url)
      var resultArray: Array<Record<string, string>> = []
      res.forEach(element => {
        let result: Record<string, string> = {
          id: element['id'],
          date: element['date'],
          description: element[`description_${this.currentLang}`],
          content: element[`content_${this.currentLang}`],
          image: element[`image_${this.currentLang}`],
        }
        resultArray.push(result)
      })
      // 將結果照日期排序
      resultArray.sort((a, b) => {
        if (a.date < b.date) {
          return 1;
        }
        if (a.date > b.date) {
          return -1;
        }
        return 0;
      }
      )
      this.awards = resultArray
      console.log(this.awards)
    },
  },
  created() {
    this.getAwards();
  }
});
