
import { defineComponent } from "vue";
import Title from "@/components/Title.vue";
import Products from "@/components/Products.vue";
import Features from "@/components/Features.vue";

export default defineComponent({
  name: 'Solutions',
  components: {
    Products,
    Features,
    Title,
  },
  data() {
    return {
    }
  },
  methods: {

  },

});
