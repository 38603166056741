
import { defineComponent, ref } from "vue";
import { Waypoint } from "vue-waypoint";
import HomeView from "@/views/Home.vue";
import AboutView from "@/views/About.vue";
import Footer from "@/views/Footer.vue";
import ContactView from "@/views/Contact.vue";
import SolutionsView from "@/views/Solutions.vue";
import { onBeforeRouteUpdate, onBeforeRouteLeave } from 'vue-router';
import { Collapse } from 'bootstrap';

export default defineComponent({
  components: {
    HomeView,
    AboutView,
    SolutionsView,
    ContactView,
    Footer,
    Waypoint,
  },
  data() {
    return {
      isTop: true,
      currentLang: localStorage.getItem("currentLang")
    };
  },
  computed: {
    isHome() {
      return this.$router.currentRoute.value.path.toLowerCase() == '/home'
    }
  },
  methods: {
    onchange(state: any) {
      console.log(this.$router.currentRoute.value.path)
      console.log(this.isHome)
      console.log(state.going);
      if (state.going == "IN") {
        this.isTop = true;
      } else {
        this.isTop = false;
      }
    },
    setLang(lang: string) {
      console.log('Setting language to', lang);
      localStorage.setItem("currentLang", lang);
      location.reload();
    },
    // Method to close the navbar when a link is clicked
    closeNavbar() {
      const navbarEl = document.getElementById('navbarOnMobile');
      if (navbarEl) {
        const navbarCollapse = new Collapse(navbarEl);
        navbarCollapse.hide();
      }
    }
  },
  setup() {
    let savedLang = localStorage.getItem("currentLang")
    localStorage.setItem("currentLang", savedLang ?? "tw");
    const navbarOnMobile = ref(null)

    // Listen to route change events
    onBeforeRouteUpdate(() => {
      closeNavbar()
    })
    onBeforeRouteLeave(() => {
      closeNavbar()
    })

    // Define a function to close the navbar
    const closeNavbar = () => {
      const navbarEl = navbarOnMobile.value;
      if (navbarEl) {
        const navbarCollapse = new Collapse(navbarEl);
        navbarCollapse.hide();
      }
    }
  },
});
