
import { defineComponent } from "vue";


export default defineComponent({
  name: 'Home',
  data() {
    return {
      currentLang: localStorage.getItem("currentLang"),
      carousel: new Array<Record<string, string>>()
    }
  },
  methods: {
    async getCarousel() {
      const url = 'herocarousel'
      let res = await this.$api.get(url)
      var resultArray: Array<Record<string, string>> = []
      res.forEach(element => {
        let result: Record<string, string> = {
          id: element['id'],
          order: element['order'],
          heading: element[`heading_${this.currentLang}`],
          image: element[`image_${this.currentLang}`],
        }
        resultArray.push(result)
      })
      this.carousel = resultArray
      console.log(this.carousel)
    },
  },
  created() {
    this.getCarousel()
  }
});
