
import { defineComponent } from "vue";


export default defineComponent({
    name: 'GoogleMap',
    data() {
        return {
            center: { lat: 25.03597472049808, lng: 121.57494007042035 },
            markers: [
                {
                    position: {
                        lat: 25.03597472049808, lng: 121.57494007042035
                    },
                }
                , // Along list of clusters
            ]
        }
    },
});
