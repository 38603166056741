
import { defineComponent } from 'vue';
import MemberPositionTag from './MemberPositionTag.vue';

export default defineComponent({
  name: 'MemberCard',
  props: {
    name: String,
    content: String,
    imgUrl: String,
    position: String
  },
  components:{
    MemberPositionTag
  }
});
